import { PageIdentifier } from '@spotify-internal/ubi-types-js';

type FrontdoorPageId = `${PageIdentifier}` & `mrkt/frontdoor/${string}`;

const defaultPageId: FrontdoorPageId = 'mrkt/frontdoor/unknown';

const pageIdMap = new Map<FrontdoorPageId, RegExp>()
  .set('mrkt/frontdoor/blog', /^\/blog$/)
  .set('mrkt/frontdoor/blog/content', /^\/blog\/[^\/]+$/)
  .set('mrkt/frontdoor/claim', /^\/claim$/)
  .set('mrkt/frontdoor/discovery-mode', /^(\/page)?\/discovery-mode$/)
  .set('mrkt/frontdoor/events', /^(\/page)?\/events$/)
  .set('mrkt/frontdoor/features', /^(\/page)?\/features$/)
  .set('mrkt/frontdoor/get-started', /^(\/page)?\/get-started$/)
  .set('mrkt/frontdoor/home', /^\/home$/)
  .set('mrkt/frontdoor/marquee', /^(\/page)?\/marquee$/)
  .set('mrkt/frontdoor/merch', /^(\/page)?\/merch$/)
  .set('mrkt/frontdoor/playlisting', /^(\/page)?\/playlisting$/)
  .set('mrkt/frontdoor/providers', /^\/providers$/)
  .set('mrkt/frontdoor/stream-on', /^(\/page)?\/stream-on$/)
  .set('mrkt/frontdoor/videos', /^\/videos(\/[^\/]+)?$/)
  .set('mrkt/frontdoor/videos/content', /^\/video\/[^\/]+$/)
  .set(
    'mrkt/frontdoor/your-wrapped',
    /^(\/page)?\/your-wrapped-soundcheck-for-artists$/,
  )
  .set(
    'mrkt/frontdoor/get-ready-for-wrapped',
    /^(\/page)?\/get-ready-for-wrapped$/,
  )
  .set('mrkt/frontdoor/campaign-kit', /^(\/page)?\/campaign-kit$/)
  .set('mrkt/frontdoor/analytics', /^(\/page)?\/analytics$/)
  .set('mrkt/frontdoor/songwriting', /^(\/page)?\/songwriting$/)
  .set('mrkt/frontdoor/showcase', /^(\/page)?\/showcase$/)
  .set('mrkt/frontdoor/canvas', /^(\/page)?\/canvas$/)
  .set('mrkt/frontdoor/live-events', /^(\/page)?\/live-events$/)
  .set('mrkt/frontdoor/merch-live-and-more', /^(\/page)?\/merch-live-and-more$/)
  .set('mrkt/frontdoor/video-and-visuals', /^(\/page)?\/video-and-visuals$/)
  .set('mrkt/frontdoor/clips', /^(\/page)?\/clips$/)
  .set(
    'mrkt/frontdoor/artificial-streaming',
    /^(\/page)?\/artificial-streaming$/,
  )
  .set('mrkt/frontdoor/new-releases', /^(\/page)?\/new-releases$/)
  .set('mrkt/frontdoor/countdown-pages', /^(\/page)?\/countdown-pages$/)
  .set(
    'mrkt/frontdoor/spotify-for-artists-marketing-awards',
    /^(\/page)?\/spotify-for-artists-marketing-awards$/,
  )
  .set(
    'mrkt/frontdoor/spotify-music-studios',
    /^(\/page)?\/spotify-music-studios$/,
  );

export function getPageId(asPath: string) {
  // remove query and hash sections
  const pathname = asPath.split(/\?|#/)[0];

  for (const [pageId, regex] of pageIdMap) {
    if (regex.test(pathname)) {
      return pageId;
    }
  }

  return defaultPageId;
}
